import React from 'react'
import { Link } from 'react-router-dom';
import "./DarkNavbar.css";

function DarkNavbar() {
  return (
    <div className='darknavbar'>
        <div className='darknavbar_Left'>
            <Link to="/home"> <p>TUSHAR SHARMA</p> </Link>
        </div>
        <div className='darknavbar_Right'>
            <Link to='/'> <p>WORK</p> </Link>
            <Link to='/about'> <p>ABOUT</p> </Link>
            <Link to='/playground'> <p>PLAYGROUND</p> </Link>
            <Link to='/resume'> <p>RESUME</p> </Link>
        </div>
    </div>
  )
}

export default DarkNavbar