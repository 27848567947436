import React, { useState, useRef, useEffect } from 'react';
import Footer from './Footer';
import Navbar2 from './Navbar2';
import "./NewPlayground.css";

export default function NewPlayground() {
  const videos = [
    { 
      id: 1, 
      src: './images/Video1.mov', 
      title: 'Motivating users to create a credit plan for user with bad credit score',
      image: './images/Credit.svg',
      bgColor: '#D5F7E4' // Light blue for card top
    },
    { 
      id: 2, 
      src: './images/Gif2.mov', 
      title: 'Visualizing property type for a mortgage lead generation form',
      image: './images/House.svg',
      bgColor: '#F3BAB8' // Light pink for card top
    },
    { 
        id: 3, 
        src: './images/Video3.mov', 
        title: 'Visualizing mortgage rates',
        image: './images/chart.svg',
        bgColor: '#ffffff' // Light pink for card top
      },
      { 
        id: 4, 
        src: './images/Video4.mov', 
        title: 'Creating an educating experience',
        image: './images/Edu.svg',
        bgColor: '#CFEDEB' // Light pink for card top
      },
      { 
        id: 5, 
        src: './images/Video5.mov', 
        title: 'Providing subtle feedback',
        image: './images/Bulb2.svg',
        bgColor: '#FFDDCB' // Light pink for card top
      },
      { 
        id: 6, 
        src: './images/Video6.mov', 
        title: 'Providing assurance before starting the form',
        image: './images/Form.svg',
        bgColor: '#EBEAFE' // Light pink for card top
      },
  ];

  // State to manage video play/pause and end statuses
  const [videoStates, setVideoStates] = useState(
    videos.map(() => ({ isPlaying: false, isEnded: false }))
  );

  // Array of refs for managing multiple video elements
  const videoRefs = useRef([]);

  useEffect(() => {
    videoRefs.current = videoRefs.current.slice(0, videos.length);
  }, [videos.length]);

  // Toggle play/pause state
  const togglePlayPause = (index) => {
    const newStates = [...videoStates];
    const video = videoRefs.current[index];

    if (newStates[index].isPlaying) {
      video.pause();
    } else {
      video.play();
    }

    newStates[index].isPlaying = !newStates[index].isPlaying;
    newStates[index].isEnded = false;
    setVideoStates(newStates);
  };

  // Handle video replay
  const handleReplay = (index) => {
    const video = videoRefs.current[index];
    video.currentTime = 0;
    video.play();

    const newStates = [...videoStates];
    newStates[index].isPlaying = true;
    newStates[index].isEnded = false;
    setVideoStates(newStates);
  };

  // Handle when a video ends
  const handleVideoEnd = (index) => {
    const newStates = [...videoStates];
    newStates[index].isPlaying = false;
    newStates[index].isEnded = true;
    setVideoStates(newStates);
  };

  return (
    <div className='newPlayground'>
      <Navbar2 />

      <div className='playground_Wrapper'>
        <div className='playgroundLeft'>
          <div className='playgroundLeft_Top'>
            <p>Welcome</p>
          </div>
          <div className='playgroundLeft_Bottom'>
            <p>To <span style={{ color: "#FB8842" }}>Playground</span></p>
          </div>
        </div>
        <div className='playgroundRight'>
          <p>Hover over each card to see its relative smooth moves (animations).</p>
        </div>
      </div>

      {/* Video Cards */}
      <div className='playgroundAniCards'>
        {videos.map((video, index) => (
          <div key={video.id} className="card" >
            {/* Card Top with background color */}
            <div 
              className='cardTop' 
              style={video.id === 5 ? { backgroundColor: video.bgColor, paddingBottom: "50px", paddingTop: "0px", position: 'relative'} : {backgroundColor: video.bgColor, position: 'relative'}}
            >
              {/* Video */}
              <video disablePictureInPicture
                ref={(el) => videoRefs.current[index] = el}
                muted
                onEnded={() => handleVideoEnd(index)}
                loop={false}
              >
                <source src={video.src} type="video/mp4" />
              </video>

              {/* Play/Pause/Replay Buttons */}
              <button 
                className='playPauseButton' 
                onClick={() => 
                  videoStates[index].isEnded 
                    ? handleReplay(index) 
                    : togglePlayPause(index)
                }
              >
                {videoStates[index].isEnded ? (
                  <img className='replay' src='./images/Replay.svg' alt="Replay" />
                ) : videoStates[index].isPlaying ? (
                  <img className='pause' src='./images/Pause.svg' alt="Pause" />
                ) : (
                  <img className='play' src='./images/Play.svg' alt="Play" />
                )}
              </button>
            </div>

            {/* Card Bottom Content */}
            <div className='cardBottom'>
              <img src={video.image} alt="Card Icon" />
              <p>{video.title}</p>
              
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}
