import React from 'react';
import "./About.css"
import Footer from './Footer';
import Navbar2 from './Navbar2';
import transition from '../transition';

function About() {
  return (
    <div className='about'>
      <Navbar2 />    
      <div className='homeLeft_rocket'>
                    <div style={{display: "flex"}} className='homeLeft_Top_rocket'>
                        <p style={{color: "black", paddingLeft: "104px", marginRight: "30px"}}>About Me</p>
                        <img style={{marginTop: "100px"}} src="/images/AboutLine.svg" alt="line" />
                    </div>
                </div>

                <div className='info'>
                  <img src='/images/Me.svg' alt="my image" />
                  <div className='info_text'>
                    <h2>   &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp;  I’m passionate about creating digital experiences with an impact on users lives. As an open and reliable person I embrace user feedback and turn it into usable products.</h2>
                    <p>With a background of four years in computer engineering, 1.5 years of design experience, and currently enrolled in my second semester of a design degree, I have established a strong foundation in both fields. I possess the ability to think like a designer, crafting beautiful designs, while also understanding the practical constraints and challenges involved in bringing those designs to life. I excel at empathizing and effectively communicating with both users and developers.
Setting aside my professional pursuits, my hobbies include sketching portraits, swimming, and engaging in volunteer work."</p>
                  </div>
                </div>


                <div className='projects_title about_title'>
      <div className='Project_Category'>
          <p>I Create</p>
          <p><span>Sketches </span></p>
        </div>
        <img src="/images/Line.svg" alt="line" />
      </div>

      <div className='sketches'>
        <div className='sketch'>
          <img src="/images/Sketch1.jpg" alt="sketch" />
        </div>
        <div className='sketch'>
          <img src="/images/Sketch2.jpg" alt="sketch" />
        </div>
        <div className='sketch'>
          <img src="/images/Sketch3.png" alt="sketch" />
        </div>
      </div>

      <div className='sketches sketches2'>
        <div className='sketch sketch2'>
          <img src="/images/Sketch4.png" alt="sketch" />
        </div>
        <div className='sketch '>
          <img src="/images/Sketch5.png" alt="sketch" />
        </div>
        <div className='sketch'>
          <img src="/images/Sketch6.png" alt="sketch" />
        </div>
      </div>

      <Footer />



    </div>
  )
}

export default transition(About);