import React, { useState } from 'react'
import Rocket from './Rocket';
import "./Privacy.css";
import Navbar2 from './Navbar2';
import Footer from './Footer';

function Privacy() {

    const [isVerified, setIsVerified] = useState(false);
  
    const checkPw = () => {
      // gets the current input value
      const answer = document.getElementById("password").value;
    
      if (answer === "12345") { 
        setIsVerified(true);
      } else {
        alert("Sorry, the passowrd you've entered is incorrect");
      }
    };


  return (
    <div className='my_form'>
        <>
         {isVerified ?  <Rocket />

            :
          (
            <>
            <Navbar2 />
            <img className='my_form_Image' src='/images/Rocket_Logo.svg' alt='Rocket Mortgage logo' />
            <form className='form' onSubmit={checkPw}>
            <input id="password" name="password" type="password" />
            <button>OPEN CASE STUDY</button>
            </form>
            <Footer />
            </>
          )
        }
        </>
    </div>
  )
}

export default Privacy