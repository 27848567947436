import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import "./Navbar.css";
      
const Ul = styled.ul`
  margin: auto;
  list-style: none;
  display: flex;
  flex: 0.6;

  .sc-hLQTFJ, .jIRkVG{
    overflow-x: hidden !important;
   }
   
  .navbar_links > a{
    height: 25px;
    font-size: 15px;
    text-decoration: none;
    color: #505050;
    font-weight: 500;
    letter-spacing: 1.5px;
    margin: auto auto auto 60px;
    display: inline-block;
    position: relative;
  }

  .active {
      color: black !important;
      font-weight: bold !important;
  }

  .navbar_links > a::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1.5px;
    bottom: 0;
    left: 0;
    background-color: #FB8842;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .navbar_links > a:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  @media screen and (max-width: 900px) {
    z-index: 10;
    flex-flow: column nowrap;
    background-color: white !important;
    position: fixed;
    top: 0;
    backdrop-filter: blur(25px);
    opacity: 1;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    height: 100vh;
    width: 100vw;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    a {
      color: #333;
      font-size: 16px;
      font-weight: 500;
    }
    .hide{
      display: none;
    }
    a > p {
      color: black;
      display: block;
    }
    a > p:hover {
      color: white;
    }
    .dropbtn {
      color: black;
    }
    .dropbtn:hover {
      color: white;
    }
  }
`;

const StyledBurger = styled.div`
  width: 2rem;
  height: 1.5rem;
  margin-top: 30px;
  margin-right: 25px;
  z-index: 20;
  display: none;
  cursor: pointer;
  @media (max-width: 900px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
 
  div {
    z-index:100;
    width: 1.5rem;
    height: 1.5px;
    background-color: ${({ open }) => (open ? "#000000" : "#FB8842")};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const Burger = () => {
  const [open, setOpen] = useState(false);


  useEffect(() => {

  const activePage = window.location.pathname;
  console.log(activePage);

  const navLink = document.querySelectorAll("a").forEach(link =>{
    if(link.href.includes(`${activePage}` )){
      console.log(`${activePage}`);
      link.classList.add("active");
    }
  })

}, [])


  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>

    <Ul open={open}>
      <div className="navbar_links">
          <Link  onClick={() => setOpen(!open)} to="/">HOME</Link>
          <Link onClick={() => setOpen(!open)} to="/about">ABOUT</Link>
          <Link onClick={() => setOpen(!open)} to="/playground">PLAYGROUND</Link>
          <a onClick={() => setOpen(!open)} href="https://drive.google.com/file/d/1IUK6912gSqyFOd3Q8unu7uYmUBOd7ayy/view?usp=sharing" target="_blank">RESUME</a>
          {/* <Link onClick={() => setOpen(!open)} to="/contact">CONTACT</Link> */}
      </div>
      
      {/* <Link onClick={() => setOpen(!open)} to="/Discover">
        <p>EXPLORE</p>
      </Link>
      <Link onClick={() => setOpen(!open)} className="hide" to="/">
        <h1>HAIRFOX</h1>
      </Link>
      <Link onClick={() => setOpen(!open)} to="/About">
        <p>ABOUT</p>
      </Link>
      <Link onClick={() => setOpen(!open)} to="/Contact">
        <p>CONTACT US</p>
      </Link> */}
    </Ul>

    </>
  );
};
export default Burger;